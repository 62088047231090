<template>
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 7.5C20 11.6 20.2 12 22.4 12H24.8L24.6 24.2C24.4 31 24.5 37.1 24.8 37.7C25.1 38.5 27.2 39 30.6 39H36L36.1 26.7C36.2 20 36.2 13.9 36.1 13.2C36 12.6 36.8 12 38 12C39.7 12 40 11.4 40 7.5V3H30H20V7.5ZM38.8 7.2L39.1 10H30.6H22V7V3.9L30.3 4.2C38.4 4.5 38.5 4.5 38.8 7.2ZM34 12.9C34 13.6 28.9 16 27.4 16C27.2 16 27 15.1 27 14C27 12.4 27.7 12 30.5 12C32.4 12 34 12.4 34 12.9ZM34 16.8C34 17.3 32.4 18.1 30.5 18.7C26.6 19.9 25.6 18.5 29.3 17C32.2 15.9 34 15.8 34 16.8ZM33.5 20.9C31.9 22.4 27 23.4 27 22.2C27 21.1 29.1 20.3 32.5 20.1C33.7 20 34 20.4 33.5 20.9ZM33.4 24.9C32.9 25.4 31.3 26 29.8 26.4C25.8 27.3 26.3 25.2 30.3 24.2C33.7 23.2 34.9 23.5 33.4 24.9ZM34 28C34 28.5 33.9 29 33.7 29C33.6 29 32 29.4 30.2 29.9C27.7 30.6 27 30.6 27.3 29.7C27.5 29.1 28.7 28.3 30.1 27.9C33.6 26.9 34 26.9 34 28ZM34 31.9C34 32.8 30.7 34 28.3 34C26.1 34 26.9 32.2 29.3 31.7C33.2 30.9 34 31 34 31.9ZM34 36C34 37.6 33.3 38 30.5 38C26.1 38 26 36.6 30.3 35.2C32 34.7 33.6 34.2 33.8 34.1C33.9 34 34 34.9 34 36Z"
      fill="currentColor"
    />
    <path
      d="M4.7 18.7C4.3 19 4 20 4 20.9C4.1 22.3 4.2 22.3 5.4 20.7C6.3 19.6 8.1 19 10.7 19C14.2 19 14.9 19.4 16.8 22.9L19 26.8L16.9 30.4C15 33.6 14.4 34 10.5 34C6.7 34 6 33.6 4.2 30.6C3 28.6 2.4 26.2 2.7 25.1C3.4 22.5 2.4 22.4 1.1 24.9C0.3 26.4 0.6 27.9 2.6 31.4C5.1 36 5.2 36 10.4 36C15.7 36 15.7 36 18.3 31.2L20.8 26.5L18.3 22.2C15.9 18.1 15.7 18 10.6 18C7.7 18 5 18.3 4.7 18.7Z"
      fill="currentColor"
    />
    <path
      d="M7.20001 23.2C5.70001 24.7 5.70001 28.3 7.20001 29.8C10 32.6 15 30.5 15 26.5C15 25.3 14.5 23.9 13.8 23.2C13.1 22.5 11.7 22 10.5 22C9.30001 22 7.90001 22.5 7.20001 23.2ZM13 27.1C13 28.1 12.3 29 11.5 29C10 29 9.40001 25.9 10.6 24.7C11.7 23.7 13 25 13 27.1Z"
      fill="currentColor"
    />
    <path
      d="M46.2 26.3C43.2 29.4 43.1 29.7 44.7 31.2C46 32.6 46.1 33.2 45.1 34.4C44.3 35.3 43.4 35.7 42.9 35.4C42.4 35.1 42 35.6 42 36.4C42 37.3 41.3 38 40.5 38C39.7 38 39 38.7 39 39.5C39 40.3 38.3 41 37.4 41C36.6 41 36.2 41.4 36.5 42C36.8 42.5 36.4 43 35.6 43C34.7 43 34 43.7 34 44.5C34 45.3 33.3 46 32.5 46C31.7 46 31 46.7 31 47.5C31 48.3 30.6 49 30.1 49C29.6 49 28.9 49.8 28.6 50.7C28.2 51.8 29 53.5 31.2 55.7C32.9 57.5 34.7 59 35 59C35.4 59 39.8 54.9 44.9 49.9C52.6 42.4 54.4 41.1 55.6 42.1C56.8 43.1 57.7 42.7 60.6 39.9L64 36.6L57.3 29.8C53.6 26.1 50.3 23 50 23C49.7 23 48 24.5 46.2 26.3ZM59.5 39L57.4 41L51.5 35C45.7 29.1 45.6 29 47.5 27L49.4 24.9L55.4 30.9L61.5 37L59.5 39ZM51.8 39.6C51.4 39.9 49.8 39.4 48.1 38.4C45.7 37 45.3 36.3 46.2 35.2C47.1 34.1 47.8 34.3 49.9 36.4C51.3 37.8 52.1 39.2 51.8 39.6ZM47.1 39.5C48.7 40.4 49.8 41.5 49.5 42.1C48.8 43.1 43 40.2 43 38.8C43 37.7 43.7 37.8 47.1 39.5ZM44.5 42.2C47.1 43.4 47.9 45 45.8 45C44.1 44.9 39.1 41.1 40.7 41C41.4 41 43.1 41.5 44.5 42.2ZM44.4 47.1C44.1 47.6 42.4 47.2 40.5 46.3C38.6 45.4 37.3 44.3 37.6 43.9C37.9 43.4 39.6 43.8 41.5 44.7C43.4 45.6 44.7 46.7 44.4 47.1ZM39.1 47.6C40.8 48.4 41.9 49.4 41.6 49.7C40.9 50.5 35 48 35 46.9C35 45.7 35.6 45.8 39.1 47.6ZM36.5 50.2C37.9 50.8 39 51.7 39 52.2C39 53.3 38.3 53.2 35 51.5C30.3 49.1 31.6 47.9 36.5 50.2ZM34 52.8C36.2 54.2 36.7 54.9 35.8 55.8C34.9 56.7 34.1 56.5 32.3 54.8C30.2 52.8 29.3 51 30.5 51C30.7 51 32.3 51.8 34 52.8Z"
      fill="currentColor"
    />
    <path
      d="M3.80001 43.1L0.100006 48.2L2.60001 53.8C4.00001 56.9 5.20001 59.5 5.30001 59.6C5.40001 59.7 8.20001 60.1 11.6 60.5L17.7 61.2L21.4 56.2L25 51.2L22.8 45.5C20.4 39.6 20.3 39.6 12 38.6C7.50001 38 7.50001 38.1 3.80001 43.1ZM16.3 40.9C18.5 41.4 19.6 42.6 21.2 46.2L23.2 50.9L20.4 54.9C17.9 58.4 17 59 13.8 59C7.80001 59 5.70001 57.7 3.80001 52.7L2.20001 48.2L5.20001 44.1C8.30001 39.8 9.80001 39.4 16.3 40.9Z"
      fill="currentColor"
    />
    <path
      d="M8.7 45.8C6.5 48.2 6.6 51.3 8.8 53.3C12.5 56.6 18 54.4 18 49.6C18 44.8 11.8 42.3 8.7 45.8ZM13 46.4C13 46.6 12.1 47 11 47.3C9.9 47.6 9.2 47.4 9.6 46.9C10.1 46 13 45.6 13 46.4ZM15.7 49.6C16.4 51.6 13.5 54.1 11.4 53.3C9.7 52.7 9.6 50.8 11.2 49.2C12.9 47.5 15 47.7 15.7 49.6Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'MountingAccessoriesIcon',
};
</script>
