import VueI18n from '../plugins/i18n';

export const roles = {
  EMPLOYEE: 'EMPLOYEE',
  COMPANY_ADMIN: 'COMPANY_ADMIN',
  SYSADMIN: 'SYSADMIN',
  SALESMAN: 'SALESMAN',
};

export const rolesFunc = () => {
  return {
    EMPLOYEE: {
      value: 'EMPLOYEE',
      text: VueI18n.t('statics.roles.EMPLOYEE'),
    },
    COMPANY_ADMIN: {
      value: 'COMPANY_ADMIN',
      text: VueI18n.t('statics.roles.COMPANY_ADMIN'),
    },
    SYSADMIN: {
      value: 'SYSADMIN',
      text: VueI18n.t('statics.roles.SYSADMIN'),
    },
    SALESMAN: {
      value: 'SALESMAN',
      text: VueI18n.t('static.roles.SALESMAN'),
    },
  };
};
