import { selectedLanguageComputed } from '../store/helper';

export const formMixin = {
  computed: {
    ...selectedLanguageComputed,
  },
  watch: {
    selectedLanguage: function () {
      this.$refs.form.resetValidation();
    },
  },
};
