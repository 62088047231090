module.exports.wizardSubStepsNames = Object.freeze({
  ROOF_TYPE: 0,
  POUR_TYPE: 1,
  SCREW_TYPE: 2,
  HANDLE_TYPE: 3,
  TCS_TYPE: 4,
  RAFTER_SPACING: 5,
  PANEL_ORIENTATION: 6,
  NUMBER_OF_PANEL_ROWS: 7,
  NUMBER_OF_MODULES: 8,
  TYPE_OF_CLAMPS: 9,
});
