import { roles } from '../static/roles';
import { userDataComputed } from '../store/helper';

export const permissionRolesMixin = {
  computed: {
    ...userDataComputed,
    isLoggedIn() {
      return !!this.userData;
    },
    isSysAdmin() {
      return this.userData.role === roles.SYSADMIN;
    },
    isSalesman() {
      return this.userData.role === roles.SALESMAN;
    },
    isSysAdminOrSalesman() {
      return this.userData.role === roles.SYSADMIN || this.userData.role === roles.SALESMAN;
    },
    isCompanyAdmin() {
      return this.userData.role === roles.COMPANY_ADMIN;
    },
    userReceivesDiscount() {
      return this.userData.isUserEntitledToDiscount;
    },
  },
};
