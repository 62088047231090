<template>
  <div class="switcher">
    <v-col class="px-0 mx-0" :class="onFooter ? 'py-0' : 'pl-0'">
      <v-select
        v-model="currency"
        :items="currencies"
        solo
        light
        @change="onChangeCurrency()"
        :dense="onFooter"
        background-color="transparent"
        :dark="darkTheme"
        hide-details="true"
      >
        <template v-slot:selection="{ item }">
          {{ returnCustomSelection(item) }}
        </template>
      </v-select></v-col
    >
    <confirm-modal
      :open="openModal"
      :title="$t('components.currencySwitcher.modal.title')"
      :agree="$t('components.currencySwitcher.modal.agree')"
      :disagree="$t('components.currencySwitcher.modal.disagree')"
      color="error-primary"
      v-on:decline="decline"
      v-on:accept="accept"
      >{{ $t('components.currencySwitcher.modal.text') }}</confirm-modal
    >
  </div>
</template>
<script>
import { supportedCurrencies } from '../static/supportedCurrencies';
import {
  userDataComputed,
  selectedCurrencyComputed,
  specialTotalPriceComputed,
  cartDefaultAmountComputed,
} from '../store/helper';
import ConfirmModal from './modals/ConfirmModal.vue';

export default {
  name: 'CurrencySwitcher',
  components: { ConfirmModal },
  data() {
    return {
      currency: '',
      openModal: false,
      acceptChange: false,
    };
  },
  props: {
    onFooter: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currencies() {
      return Object.values(supportedCurrencies);
    },
    darkTheme() {
      return this.onFooter ? false : this.userData ? true : false;
    },
    ...selectedCurrencyComputed,
    ...userDataComputed,
    ...specialTotalPriceComputed,
    ...cartDefaultAmountComputed,
  },
  methods: {
    productSpecialTotalPriceChanged() {
      return this.specialTotalPrice !== this.cartDefaultAmount;
    },
    returnCustomSelection() {
      return this.currencies.find((item) => item.value === this.selectedCurrency)?.text;
    },
    isPathToOpenModal() {
      const currentPath = this.$route.name;
      const pathToOpenModal = ['MountingAccessories', 'WizardSummary', 'TransactionSummary'];
      return pathToOpenModal.includes(currentPath);
    },
    onChangeCurrency() {
      if (this.isPathToOpenModal() && this.productSpecialTotalPriceChanged()) {
        this.openModal = true;
      } else {
        this.openModal = false;
        this.changeCurrency();
      }
    },
    changeCurrency() {
      const updatedUserData = { ...this.userData, currency: this.currency };
      this.$store.dispatch('changeCurrency', { id: this.userData?._id, updatedUserData });
    },
    accept() {
      this.changeCurrency();
      this.openModal = false;
    },
    decline() {
      this.currency = this.selectedCurrency;
      this.openModal = false;
    },
  },
  created() {
    this.currency = this.selectedCurrency;
  },
};
</script>
<style lang="scss" scoped>
.switcher {
  width: 5.3rem;
}
::v-deep .v-select__slot {
  max-width: 77% !important;
}
::v-deep .v-input__slot {
  padding: 0 6px !important;
}

::v-deep .v-text-field .v-input__append-inner {
  padding-left: 0 !important;
}

$select-selections-margin: 0px !important;
</style>
