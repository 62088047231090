<template>
  <v-btn
    elevation="0"
    @click="onclick"
    :loading="loading"
    :color="color ? color : 'primary'"
    class="white--text pa-4 v-size--large"
    :to="to ? to : ''"
    x-large
    :class="childclass"
    :disabled="disabled"
    ><span class="px-2" style="white-space: normal"> <slot /> </span>
  </v-btn>
</template>
<script>
export default {
  name: 'PrimaryButton',
  props: {
    onClick: {
      type: Function,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'primary',
    },
    to: {
      type: [String, Object],
      default: '',
    },
    childclass: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    onclick() {
      if (this.onClick) {
        return this.onClick;
      } else {
        return () => {};
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
