<template>
  <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
    <navbar></navbar>
    <v-main class="positioning">
      <v-container class="global-spacing">
        <router-view></router-view>
      </v-container>
      <grid-pattern-section></grid-pattern-section>
    </v-main>
    <need-help></need-help>
    <external-organization-floating-snackbar
      v-if="isCreatingOfferForExternalClient"
    ></external-organization-floating-snackbar>
    <footer-component></footer-component>
  </v-app>
</template>

<script>
import Navbar from './components/Navbar.vue';
import NeedHelp from './components/NeedHelp.vue';
import GridPatternSection from './components/GridPatternSection.vue';
import FooterComponent from './components/Footer.vue';
import ExternalOrganizationFloatingSnackbar from './components/ExternalOrganizationFloatingSnackbar.vue';
import { organizationComputed, userDataComputed } from './store/helper.js';

export default {
  name: 'App',
  components: {
    Navbar,
    NeedHelp,
    GridPatternSection,
    FooterComponent,
    ExternalOrganizationFloatingSnackbar,
  },
  metaInfo() {
    const title = this.$t('meta.appTitle');
    return {
      titleTemplate: title,
    };
  },
  computed: {
    theme() {
      return 'light';
    },
    ...userDataComputed,
    ...organizationComputed,
    isCreatingOfferForExternalClient() {
      return !!this.organization.name;
    },
  },
  created() {
    if (this.userData) {
      this.$store.dispatch('initLocale');
    }
  },
};
</script>

<style lang="scss">
@import './scss/main.scss';
@import './scss/variables.scss';

.line {
  width: 100%;
  margin: auto;
  height: 0px;

  border: 1px solid #c4c4c4;
}

.stretch-height {
  height: 100% !important;
}

.positioning {
  position: relative;
  z-index: 1;
}
</style>
