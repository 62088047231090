import { mapGetters } from 'vuex';

export const discountsComputed = {
  ...mapGetters(['discounts']),
};

export const userDataComputed = {
  ...mapGetters(['userData']),
};

export const orderDetailsComputed = {
  ...mapGetters(['orderDetails']),
};

export const pourTypeComputed = {
  ...mapGetters(['pourType']),
};

export const rafterSpacingComputed = {
  ...mapGetters(['rafterSpacing']),
};

export const panelOrientationComputed = {
  ...mapGetters(['panelOrientation']),
};

export const verticalRowNumberComputed = {
  ...mapGetters(['verticalRowNumber']),
};

export const horizontalRowNumberComputed = {
  ...mapGetters(['horizontalRowNumber']),
};

export const verticalModulesNumbersComputed = {
  ...mapGetters(['verticalModulesNumbers']),
};

export const horizontalModulesNumbersComputed = {
  ...mapGetters(['horizontalModulesNumbers']),
};

export const declaredPowerComputed = {
  ...mapGetters(['declaredPower']),
};

export const clampTypeComputed = {
  ...mapGetters(['clampType']),
};

export const screwTypeComputed = {
  ...mapGetters(['screwType']),
};

export const handleTypeComputed = {
  ...mapGetters(['handleType']),
};

export const tcsTypeComputed = {
  ...mapGetters(['tcsType']),
};

export const countOfSelectedModulesComputed = {
  ...mapGetters(['countOfSelectedModules']),
};

export const countOfSelectedInvertersComputed = {
  ...mapGetters(['countOfSelectedInverters']),
};

export const countOfSelectedOptimizatorsComputed = {
  ...mapGetters(['countOfSelectedOptimizers']),
};

export const selectedModulesComputed = {
  ...mapGetters(['selectedModules']),
};

export const selectedInvertersComputed = {
  ...mapGetters(['selectedInverters']),
};

export const selectedOptimizersComputed = {
  ...mapGetters(['selectedOptimizers']),
};

export const cartComputed = {
  ...mapGetters(['cart']),
};

export const modeComputed = {
  ...mapGetters(['mode']),
};

export const incompatibleStepsComputed = {
  ...mapGetters(['incompatibleSteps']),
};

export const incompatibleSubStepsComputed = {
  ...mapGetters(['incompatibleSubSteps']),
};

export const wizardStepComputed = {
  ...mapGetters(['wizardStep']),
};

export const wizardSubstepComputed = {
  ...mapGetters(['wizardSubstep']),
};

export const cartTotalAmountComputed = {
  ...mapGetters(['cartTotalAmount']),
};

export const wizardOptionsComputed = {
  ...mapGetters(['wizardOptions']),
};

export const specialTotalPriceComputed = {
  ...mapGetters(['specialTotalPrice']),
};

export const cartDefaultAmountComputed = {
  ...mapGetters(['cartDefaultAmount']),
};

export const missingSkusComputed = {
  ...mapGetters(['missingSkus']),
};

export const missingProductsComputed = {
  ...mapGetters(['missingProducts']),
};

export const substitutesComputed = {
  ...mapGetters(['substitutes']),
};

export const declaredPowerChangedComputed = {
  ...mapGetters(['declaredPowerChanged']),
};

export const savedAccessoriesComputed = {
  ...mapGetters(['savedAccessories']),
};

export const accessoriesRefetchComputed = {
  ...mapGetters(['accessoriesRefetch']),
};

export const deliveryComputed = {
  ...mapGetters(['delivery']),
};

export const organizationComputed = {
  ...mapGetters(['organization']),
};

export const enteringViewComputed = {
  ...mapGetters(['enteringView']),
};

export const countryCodeComputed = {
  ...mapGetters(['deliveryCountryCode']),
};

export const selectedLanguageComputed = {
  ...mapGetters(['selectedLanguage']),
};

export const selectedCurrencyComputed = {
  ...mapGetters(['selectedCurrency']),
};

export const selectedCurrencyObjComputed = {
  ...mapGetters(['selectedCurrencyObj']),
};

export const currenciesComputed = {
  ...mapGetters(['currencies']),
};

export const wizardTypeComputed = {
  ...mapGetters(['wizardType']),
};

export const constructionCalculatorAttributesComputed = {
  ...mapGetters(['constructionCalculatorAttributes']),
};

export const routeHistoryComputed = {
  ...mapGetters(['routeHistorySubCategory']),
};

export const confirmResetModal = {
  ...mapGetters(['confirmResetModal']),
};

export const roofTypeComputed = {
  ...mapGetters(['roofType']),
};

export const installationMethodComputed = {
  ...mapGetters(['installationMethod']),
};

export const inclinationAngleComputed = {
  ...mapGetters(['inclinationAngle']),
};

export const statementModalIsOpenComputed = {
  ...mapGetters(['statementModalIsOpen']),
};
