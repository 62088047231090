export const wizardTypes = Object.freeze({
  assistant: {
    name: 'assistant',
    startingPath: 'DeclaredPower',
  },
  constructionCalculator: {
    name: 'constructionCalculator',
    startingPath: 'ModulesQuantity',
  },
});
