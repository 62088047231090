import axios from 'axios';
import Vue from 'vue';
import VueI18n from '../plugins/i18n';
import store from '../store/store'; // path to your Vuex store
import router from '../router/router'; // path to your Vuex store
import { STATEMENT_NOT_ACCEPTED } from '../static/errorMessages';

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401 && error.response?.data.msg === STATEMENT_NOT_ACCEPTED) {
      Vue.toasted.global.error({ message: VueI18n.t('errorMessages.statementNotAccepted') });
      store.dispatch(`logout`);
      store.dispatch('openStatementModal');
    }

    if (
      error.response?.status === 401 &&
      router.currentRoute?.name !== 'Login' &&
      error.response?.data.msg !== STATEMENT_NOT_ACCEPTED
    ) {
      Vue.toasted.global.error({ message: VueI18n.t('errorMessages.noAuthorization') });
      store.dispatch(`logout`);
    }

    if (error.response?.status === 403) {
      Vue.toasted.global.error({
        message: VueI18n.t('errorMessages.noRightsForOperation'),
      });
      store.dispatch(`logout`);
    }

    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.data?.errorCode && error.response?.data.msg !== STATEMENT_NOT_ACCEPTED) {
      Vue.toasted.global.error({
        message: VueI18n.t(`errorMessages.${error.response.data.errorCode}`),
      });
    }
    return Promise.reject(error);
  }
);

export default axios;
