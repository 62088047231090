<template>
  <div v-if="isVisible" class="floating-button" :class="{ open: isOpen }">
    <div @click="isOpen = !isOpen">
      <v-icon v-if="isOpen" size="150px" class="icon">$floatingCloseIcon</v-icon>
      <v-icon v-else size="150px" class="icon">$floatingHelpIcon</v-icon>
    </div>
    <v-container class="white container">
      <v-form v-model="isFormValid" ref="form">
        <h3>{{ $t('components.needHelp.title') }}</h3>
        <p>{{ $t('components.needHelp.leaveAContact') }}</p>
        <h2 class="text">{{ $t('helpers.firstName') }}*</h2>
        <v-text-field
          v-model="firstName"
          elevation="0"
          :label="$t('helpers.firstName')"
          :rules="validator.nameRules"
          outlined
          solo
        ></v-text-field>
        <h2 class="text">{{ $t('helpers.email') }}*</h2>
        <v-text-field
          v-model="email"
          elevation="0"
          :label="$t('helpers.email')"
          :rules="validator.emailRules"
          outlined
          solo
        ></v-text-field>
        <h2 class="text">{{ $t('helpers.phoneNumber') }}</h2>
        <v-text-field
          v-model="phone"
          elevation="0"
          :label="$t('helpers.phoneNumber')"
          :rules="validator.addPhoneRules"
          outlined
          solo
        ></v-text-field>
        <v-row class="pb-4">
          <v-col>
            <processing-personal-data-link
              customClass="text-caption"
            ></processing-personal-data-link>
          </v-col>
        </v-row>

        <primary-button :onClick="sendInfo" :loading="loading">{{
          $t('components.needHelp.sendRequest')
        }}</primary-button>
      </v-form>
    </v-container>
  </div>
</template>
<script>
import { validators } from '../validators/Validators';
import PrimaryButton from '../components/buttons/PrimaryButton.vue';
import HelpService from '../services/HelpService';
import ProcessingPersonalDataLink from './ProcessingPersonalDataLink.vue';
import { formMixin } from '../mixins/formMixin';

export default {
  name: 'NeedHelp',
  components: { PrimaryButton, ProcessingPersonalDataLink },
  mixins: [formMixin],
  data() {
    return {
      phone: '',
      firstName: '',
      isFormValid: false,
      loading: false,
      isOpen: false,
      email: '',
    };
  },
  methods: {
    async sendInfo() {
      this.$refs.form.validate();
      if (this.isFormValid) {
        try {
          await HelpService.sendHelpRequest(this.firstName, this.email, this.phone);
          this.$toasted.global.success({ message: this.$i18n.t('components.needHelp.toasted') });
          this.isOpen = false;
        } catch (error) {
          console.error(error);
        } finally {
          this.$refs.form.reset();
        }
      }
    },
  },
  computed: {
    validator() {
      return validators;
    },
    isVisible() {
      // commented, in case changing mind of client
      // const routesWhereVisible = ['Login', 'Register', 'Options'];
      // return routesWhereVisible.includes(this.$route.name);
      return true;
    },
  },
};
</script>
<style lang="scss" scoped>
.floating-button {
  position: fixed;
  z-index: 10;
  right: 0;
  top: 50%;
  transform: translate(100%, -50%);
  transition: all 0.3s ease-in;
}

.open {
  transform: translate(0, -50%);
  box-shadow: -0.2rem 0.75rem 0.75rem rgba(0, 0, 0, 0.1);
}

.close {
  transform: translate(100%, -50%);
  box-shadow: -0.2rem 0.75rem 0.75rem rgba(0, 0, 0, 0.1);
}

.container {
  width: 450px;
  z-index: 10;
  @media screen and (max-width: 768px) {
    width: 250px;
  }
}

.icon {
  position: absolute;
  transform: translate(-50%, 60%);
  z-index: -1;
  cursor: pointer;
}
</style>
