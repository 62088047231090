<template>
  <div class="grid-pattern-section">
    <img src="@/assets/gridPattern.svg" alt="grid-pattern" />
  </div>
</template>
<script>
export default {
  name: 'GridPatternSection',
};
</script>
<style lang="scss" scoped>
.grid-pattern-section {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
  opacity: 0.2;
}
</style>
