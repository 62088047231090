import config from '../../configuration.json';
import axios from 'axios';

const url = `${config.VUE_APP_API_URL}/basket`;

class BasketService {
  static async getBasketDiscouts(cart) {
    const res = await axios.post(`${url}/discounts`, { cart });
    return res.data;
  }
}

export default BasketService;
