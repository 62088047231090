<template>
  <v-dialog transition="dialog-bottom-transition" persistent v-model="dialog" max-width="400">
    <v-card>
      <v-card-title class="text font-weight-bold"> {{ title }} </v-card-title>

      <v-card-text class="text-sm grey--text">
        {{ message }}
      </v-card-text>

      <v-container>
        <v-row justify="center" class="ma-4 mt-0">
          <v-btn
            elevation="0"
            block
            @click="agree"
            :loading="false"
            color="primary"
            class="pa-4 v-size--large white--text"
            x-large
            >{{ $t('helpers.accept') }}</v-btn
          >
        </v-row>
        <v-row justify="center">
          <v-btn class="mb-4 font-weight-medium border-bottom" color="font" text @click="cancel">
            {{ $t('helpers.cancel') }}</v-btn
          >
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ConfirmModal',
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      message: '',
      title: '',
    };
  },
  methods: {
    open(title, message) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.border-bottom:after {
  content: '\00a0';
  position: absolute;
  transform: translateY(600%);
  width: 55px;
  height: 2px;
  background: #333333;
}
</style>
