<template>
  <v-card class="floating-snackbar pa-3 white--text" color="info">
    {{ $t('components.externalOrganizationFloatingSnackbar.title') }}
    <span class="text-capitalize font-weight-bold">{{ organization.name }}</span>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon @click="closeAndFinish">
          <v-icon v-bind="attrs" v-on="on"> mdi-close-circle </v-icon>
        </v-btn>
      </template>
      <span>{{ $t('components.externalOrganizationFloatingSnackbar.close') }}</span>
    </v-tooltip>
  </v-card>
</template>
<script>
import { wizardTypes } from '../static/wizardTypes.js';
import { organizationComputed } from '../store/helper.js';
export default {
  name: 'ExternalOrganizationFloatingSnackbar',
  computed: {
    ...organizationComputed,
  },
  methods: {
    closeAndFinish() {
      this.$store.commit('finishCreatingOfferForExternalClient');
    },
  },
  mounted() {
    this.$store.dispatch('setWizardType', wizardTypes.assistant);
  },
};
</script>
<style lang="scss" scoped>
.floating-snackbar {
  bottom: 0.5rem;
  left: 0.5rem;
  position: fixed;
  z-index: 200;
  max-width: 80%;
  @media only screen and (max-width: 48em) {
    font-size: 0.875rem;
  }
}
</style>
