import { selectedLocale } from '../plugins/i18n';
import { supportedCurrencies } from '../static/supportedCurrencies';
import { selectedCurrencyComputed, userDataComputed, currenciesComputed } from '../store/helper';

export const formatCurrency = {
  computed: {
    ...selectedCurrencyComputed,
    ...userDataComputed,
    ...currenciesComputed,
  },
  methods: {
    isDefaultCurrency(currentCurrency) {
      return currentCurrency === supportedCurrencies.PLN.value;
    },
    currencyRate() {
      return this.currencies?.find((currency) => currency.name === this.selectedCurrency);
    },
    getPriceInDefaultCurrency(number) {
      return number * this.currencyRate()?.value;
    },
    currencyLabel(initialCurrencyRate = null) {
      const currencyToMatch = initialCurrencyRate
        ? initialCurrencyRate.name
        : this.selectedCurrency;
      return Object.values(supportedCurrencies).find((item) => item.value === currencyToMatch)
        ?.label;
    },
    calculateByCurrency(number, initialCurrencyRate) {
      const currencyRate = initialCurrencyRate ? initialCurrencyRate : this.currencyRate();

      return currencyRate ? number / currencyRate.value : number;
    },
    formatCurrency(number, initialCurrencyRate = null, convertEnabled = true) {
      const formattedNumber = this.getNumberFormatted(number, initialCurrencyRate, convertEnabled);

      return `${formattedNumber} ${this.currencyLabel(initialCurrencyRate)}`;
    },

    getNumberFormatted(number, initialCurrencyRate = null, convertEnabled = true) {
      const locale = this.$i18n.locale || selectedLocale();

      const language = `${locale.toLowerCase()}-${locale.toUpperCase()}`;

      const formatter = new Intl.NumberFormat(language, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      const formattedNumber = formatter.format(
        convertEnabled ? this.calculateByCurrency(number, initialCurrencyRate) : number
      );
      return formattedNumber;
    },

    getTotalPriceFormatted(price, quantity = 1) {
      return (
        +this.getNumberFormatted(price / this.currencyRate().value, null, false)
          .replace(',', '.')
          .replace(String.fromCharCode(160), '') * quantity
      );
    },

    priceWithDiscount(value, calculateQuantity = false) {
      const totalPrice = calculateQuantity ? value.quantity * value.standardPrice : value;

      return this.userData.isUserEntitledToDiscount
        ? totalPrice - totalPrice * this.userData.salesmanDiscountPercentage
        : totalPrice;
    },
  },
};
