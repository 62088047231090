export const authMixin = {
  methods: {
    async logout() {
      const wizardRegex = 'doradca';

      if (this.$router.currentRoute.path.includes(wizardRegex)) {
        const confirmed = await this.$refs.confirm.open(
          this.$i18n.t('components.navLinksSection.modal.title'),
          this.$i18n.t('components.navLinksSection.modal.text')
        );
        if (confirmed) {
          this.$store.commit('resetWizard');
          this.$store.dispatch('resetLocale');
          await this.$store.dispatch('logout');
        }
      } else {
        this.$store.dispatch('resetLocale');
        this.$store.dispatch('logout');
      }
    },
  },
};
