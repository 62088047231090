<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="512.000000pt"
    height="512.000000pt"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill="#E94F2D"
      stroke="none"
    >
      <path
        d="M2470 5104 c-180 -38 -357 -177 -439 -344 -70 -144 -71 -148 -71
-837 l0 -613 -142 0 c-363 0 -637 -107 -869 -339 -137 -137 -238 -305 -290
-483 -14 -46 -28 -78 -36 -78 -10 0 -13 90 -13 463 l0 462 53 25 c64 30 164
128 196 193 89 184 58 381 -84 523 -176 177 -452 177 -631 -1 -173 -172 -178
-438 -11 -621 28 -31 73 -65 112 -85 l65 -34 0 -472 0 -472 -57 -28 c-71 -34
-171 -131 -202 -196 -48 -99 -51 -130 -51 -499 0 -221 4 -366 11 -400 19 -86
59 -158 124 -223 101 -101 197 -135 388 -135 l112 0 23 -81 c98 -352 384 -650
740 -769 177 -59 191 -60 1162 -60 971 0 985 1 1162 60 175 59 318 148 449
279 140 141 242 312 291 490 l23 81 112 0 c130 0 197 12 272 49 116 56 213
181 240 309 15 72 15 713 0 784 -19 86 -59 158 -124 223 -101 102 -197 135
-388 135 l-113 0 -23 77 c-12 43 -38 110 -56 150 l-34 73 82 0 c113 0 207 22
307 71 169 83 307 260 345 444 22 103 22 1277 0 1380 -50 240 -260 450 -500
500 -101 21 -2036 21 -2135 -1z m2141 -315 c79 -37 133 -90 171 -167 l33 -67
0 -640 0 -640 -33 -67 c-38 -77 -92 -130 -171 -167 l-56 -26 -950 -5 -950 -5
-192 -97 c-106 -54 -195 -98 -198 -98 -3 0 -4 393 -3 873 l3 872 26 55 c46 99
120 163 222 196 34 10 235 12 1042 11 l1000 -2 56 -26z m-4081 -899 c45 -23
80 -80 80 -131 0 -74 -76 -149 -151 -149 -46 0 -108 39 -130 82 -37 72 -14
151 57 194 48 29 92 30 144 4z m1430 -1123 c0 -275 4 -293 76 -334 70 -41 86
-36 413 127 l302 150 629 0 630 0 35 -47 c54 -74 101 -170 132 -268 l28 -90 0
-650 0 -650 -28 -88 c-91 -293 -291 -493 -584 -584 l-88 -28 -945 0 -945 0
-88 28 c-293 91 -493 291 -584 584 l-28 88 0 650 0 650 28 88 c89 285 294 495
564 577 104 31 152 38 311 39 l142 1 0 -243z m-1350 -1107 l0 -450 -96 0
c-114 0 -164 21 -195 82 -18 34 -19 62 -19 370 1 375 2 379 76 424 34 21 52
24 137 24 l97 0 0 -450z m4130 430 c26 -13 47 -34 60 -60 19 -37 20 -58 20
-370 0 -312 -1 -333 -20 -370 -31 -61 -78 -80 -195 -80 l-95 0 0 450 0 450 95
0 c76 0 104 -4 135 -20z"
      />
      <path
        d="M2648 4339 c-43 -22 -78 -81 -78 -129 0 -50 35 -107 80 -130 38 -20
57 -20 890 -20 833 0 852 0 890 20 45 23 80 80 80 130 0 50 -35 107 -80 130
-38 20 -57 20 -892 20 -830 -1 -854 -1 -890 -21z"
      />
      <path
        d="M2648 3739 c-43 -22 -78 -81 -78 -129 0 -50 35 -107 80 -130 38 -20
57 -20 890 -20 833 0 852 0 890 20 45 23 80 80 80 130 0 50 -35 107 -80 130
-38 20 -57 20 -892 20 -830 -1 -854 -1 -890 -21z"
      />
      <path
        d="M1738 2089 c-68 -35 -78 -71 -78 -279 0 -161 2 -185 20 -220 23 -45
80 -80 130 -80 50 0 107 35 130 80 18 35 20 59 20 220 0 161 -2 185 -20 220
-37 73 -127 99 -202 59z"
      />
      <path
        d="M3238 2089 c-68 -35 -78 -71 -78 -279 0 -161 2 -185 20 -220 23 -45
80 -80 130 -80 50 0 107 35 130 80 18 35 20 59 20 220 0 161 -2 185 -20 220
-37 73 -127 99 -202 59z"
      />
      <path
        d="M2188 1189 c-43 -22 -78 -81 -78 -129 0 -50 35 -107 80 -130 37 -19
58 -20 370 -20 312 0 333 1 370 20 45 23 80 80 80 130 0 50 -35 107 -80 130
-37 19 -58 20 -372 20 -312 -1 -335 -2 -370 -21z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'ChatIcon',
};
</script>
