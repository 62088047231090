<template>
  <div class="switcher">
    <v-col class="px-0 mx-0" :class="onFooter ? 'py-0' : 'pl-0'">
      <v-select
        v-model="lang"
        :items="langs"
        solo
        light
        :dense="onFooter"
        background-color="transparent"
        :dark="darkTheme"
        hide-details="true"
      >
        <template v-slot:selection="{ item }">
          <div class="d-flex align-center">
            <span class="mr-1">{{ item.text }}</span>
            <country-flag size="small" :country="item.flagCode" />
          </div>
        </template>
        <template slot="item" slot-scope="data">
          <span>{{ data.item.text }}</span>
          <country-flag size="small" :country="data.item.flagCode" class="ml-1" />
        </template> </v-select
    ></v-col>
  </div>
</template>
<script>
import { supportedLanguages } from '../plugins/i18n';
import { userDataComputed, selectedLanguageComputed } from '../store/helper';
import CountryFlag from 'vue-country-flag';

export default {
  name: 'LanguageSwitcher',
  components: { CountryFlag },
  props: {
    onFooter: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    langs() {
      return supportedLanguages;
    },
    lang: {
      get() {
        return this.selectedLanguage;
      },
      set(lang) {
        this.changeLang(lang);
      },
    },
    darkTheme() {
      return this.onFooter ? false : this.userData ? true : false;
    },
    ...selectedLanguageComputed,
    ...userDataComputed,
  },
  methods: {
    changeLang(lang) {
      const updatedUserData = { ...this.userData, lang: lang };
      this.$store.dispatch('changeLocale', { id: this.userData?._id, updatedUserData });
    },
    getCorrectCodeIfFlagException(code) {
      return code !== 'en' ? code : 'gb';
    },
  },
};
</script>
<style lang="scss" scoped>
.switcher {
  width: 4.7rem;
}
::v-deep .v-select__slot {
  max-width: 100% !important;
}

::v-deep .v-input__slot {
  padding: 0 6px !important;
}
::v-deep .v-text-field .v-input__append-inner {
  padding-left: 0 !important;
}
.v-list-item {
  .flag {
    position: relative;
    &.f-it {
      left: 8px;
    }
    &.f-pl {
      left: 3px;
    }
  }
}
$select-selections-margin: 0px !important;
</style>
