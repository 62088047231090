<template>
  <span :class="newClass">
    {{ $t('components.processingPersonalDataLink.titlePartOne') }}
    <span @click="toProcessingPersonalData" class="linkTo">{{
      $t('components.processingPersonalDataLink.titlePartTwo')
    }}</span
    >.
  </span>
</template>
<script>
export default {
  name: 'ProcessingPersonalDataLink',
  props: ['customClass'],
  methods: {
    toProcessingPersonalData() {
      this.$router.push({ name: 'ProcessingPersonalData' });
    },
  },
  computed: {
    newClass() {
      return this.customClass || '';
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../scss/variables.scss';
.linkTo {
  cursor: pointer;
  color: $primary;
}
</style>
