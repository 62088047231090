<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M-0.000976562 2.66667C-0.000976562 1.2 1.19902 0 2.66569 0H13.3324V2.66667H2.66569V21.3333H13.3324V24H2.66569C1.19902 24 -0.000976562 22.8 -0.000976562 21.3333V2.66667ZM18.9004 10.6667L15.519 7.28533L17.4044 5.4L24.0044 12L17.4044 18.6L15.519 16.7147L18.9004 13.3333H10.119V10.6667H18.9004Z"
      fill="#ECECEC"
      fill-opacity="0.7"
    />
  </svg>
</template>
<script>
export default {
  name: 'LogoutIcon',
};
</script>
