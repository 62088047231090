<template>
  <div class="logo-section">
    <v-img
      contain
      height="30"
      width="50"
      v-if="$vuetify.breakpoint.sm"
      src="@/assets/logo.png"
      alt="solitan-logo"
    />
    <v-img v-else src="@/assets/logo.svg" alt="solitan-logo" />
  </div>
</template>
<script>
export default {
  name: 'NavLogoSection',
};
</script>
<style lang="scss" scoped>
.logo-section {
  margin: auto 0;
}

.logo {
  max-height: 100%;
}
</style>
