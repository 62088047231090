import Vue from 'vue';
import Vuex from 'vuex';
import router from '../router/router';
import AuthService from '../services/AuthService';
import UserService from '../services/UserService';
import createPersistedState from 'vuex-persistedstate';
import { round, sum, find, isEmpty, lte } from 'lodash';
import {
  POUR_TYPE,
  ROOF_TYPE,
  permamentPourTypes,
  tcsHandleTypes,
  permamentHandleType,
  CLAMP_TYPES,
  INSTALLATION_METHODS,
} from '../static/fotoConstants.js';
import { wizardSubStepsNames } from '../static/wizardSubStepsNames';
import discountGroups from '../static/discountGroups';
import { findCompared } from '@/utils/comparatorUtils';
import { defaultCountryCode } from '../static/defaultCountryCode';
import i18n, { selectedLocale } from '@/plugins/i18n';
import { currencyRate } from '../utils/currencyUtils';
import BasketService from '../services/BasketService';
import { wizardTypes } from '../static/wizardTypes';

Vue.use(Vuex);

const wizardResetMapped = [
  'setDeclaredPower',
  '',
  '',
  '',
  [
    '',
    'setPourType',
    'setScrewType',
    'setHandleType',
    'setTcsType',
    'setRafterSpacing',
    'setPanelOrientation',
    'setVerticalRowNumber',
    'setHorizontalRowNumber',
    'setVerticalModulesNumbers',
    'setHorizontalModulesNumbers',
    'setClampType',
  ],
  '',
  '',
  '',
];
const getPriceByQuantityAndGroup = (quantity, group, discountPrices, discountPricesQuanities) => {
  const discountPrice = discountPrices.find((disc) => disc.name === group);
  const groupQuantities = find(discountPricesQuanities, ({ min, max }) => {
    return findCompared(min, max, quantity);
  });
  const price = discountPrice['price'][groupQuantities.name];
  return price;
};

const setAccessoriesRefetch = (state) => {
  state.accessoriesRefetch = true;
};

const isDifferent = (state, newState) => {
  return state !== newState;
};

const routeForEnteringView = ({ enteringView: { name, params } }) => {
  return {
    name,
    params,
  };
};

const handleIncrementIfUserOnPourTypeSelection = (
  state,
  pourType,
  installationMethod,
  roofType
) => {
  if (permamentPourTypes.includes(pourType) || pourType !== POUR_TYPE.BLACHODACHOWKA) {
    state.wizardSubStep++;
  }

  if (installationMethod === INSTALLATION_METHODS.NonIvasive && roofType === ROOF_TYPE.FlatRoof) {
    state.wizardSubStep++;
  }
};

const handleIncrementIfUserOnScrewTypeSelection = (state, pourType) => {
  if (pourType === POUR_TYPE.BLACHODACHOWKA) {
    state.wizardSubStep++;
  }
};

const handleIncrementIfUserOnHandleTypeSelection = (state, pourType) => {
  if (
    !permamentPourTypes.includes(pourType) &&
    ![POUR_TYPE.DACHOWKA_CERAMICZNA, POUR_TYPE.KARPIOWKA].includes(pourType)
  ) {
    state.wizardSubStep++;
  }
};

const getDefaultState = () => {
  return {
    routeHistorySubCategory: '',
    discounts: [],
    locale: selectedLocale,
    userData: null,
    mode: '',
    statementModalIsOpen: false,
    enteringView: {
      name: '',
      params: null,
    },
    orderData: {
      products: [],
      deliveryData: '',
      deliveryDate: '',
      specialTotalPrice: 0,
      companyId: '',
    },
    orderInProgress: false,
    cart: [],
    incompatibleAccessories: [],
    missingSkus: [],
    missingProducts: [],
    substitutes: [],
    wizardStep: 0,
    wizardSubStep: 0,
    deliveryCountryCode: defaultCountryCode.PL,
    delivery: {
      type: '',
      name: '',
      price: 0,
      text: '',
    },
    declaredPowerChanged: false,
    organization: {
      name: '',
      clientId: '',
    },
    wizardOptions: {
      accessoriesRefetch: true,
      declaredPower: 0,
      savedAccessories: [],
      incompatibleSteps: [],
      incompatibleSubSteps: [],
      modules: [],
      inverters: [],
      optimizers: [],
      roofConstruction: {
        constructionType: '',
        roofType: ROOF_TYPE.PitchedRoof,
        pourType: POUR_TYPE.BLACHODACHOWKA,
        installationMethod: INSTALLATION_METHODS.Invasive,
        rafterSpacing: '',
        panelOrientation: '',
        screwType: '',
        handleType: '',
        tcsType: '',
        verticalModulesNumbers: [],
        horizontalModulesNumbers: [],
        clampType: CLAMP_TYPES.SREBRNE,
        inclinationAngle: '',
      },
      constructionCalculatorAttributes: { length: 0, height: 0, quantity: 0, thickness: 0 },
      wizardType: wizardTypes.assistant,
      confirmResetModal: {
        open: false,
        type: wizardTypes.assistant,
      },
    },
  };
};

const removeFromIncompatibleStepsState = (state, step) => {
  const exists = state.wizardOptions.incompatibleSteps.find((st) => st === step);
  if (exists) {
    state.wizardOptions.incompatibleSteps = state.wizardOptions.incompatibleSteps.filter(
      (st) => st !== step
    );
  }
};

const addToIncompatibleStepsState = (state, step) => {
  const exists = state.wizardOptions.incompatibleSteps.find((st) => st === step);
  if (!exists) {
    state.wizardOptions.incompatibleSteps.push(step);
  }
};

const isQuantityOfAddedProductLowerThanStock = (product, productInCart) => {
  const sumOfQuantity = parseInt(product.quantity) + parseInt(productInCart.quantity);
  return lte(sumOfQuantity, parseInt(productInCart.stock));
};

const resetModulesNumberRowsState = (state) => {
  state.wizardOptions.roofConstruction.verticalModulesNumbers = [];
  state.wizardOptions.roofConstruction.horizontalModulesNumbers = [];
  state.wizardOptions.roofConstruction.verticalRowNumber = [];
  state.wizardOptions.roofConstruction.horizontalRowNumber = [];
};

const resetModulesNumbersState = (state) => {
  state.wizardOptions.roofConstruction.verticalModulesNumbers = [];
  state.wizardOptions.roofConstruction.horizontalModulesNumbers = [];
};

const resetPourTypesOptions = (state) => {
  state.wizardOptions.roofConstruction.screwType = '';
  state.wizardOptions.roofConstruction.handleType = '';
  state.wizardOptions.roofConstruction.tcsType = '';
};

const resetWizardState = (state) => {
  state.mode = '';
  state.wizardStep = 0;
  state.wizardSubStep = 0;
  state.declaredPowerChanged = false;
  state.deliveryCountryCode = defaultCountryCode.PL;
  state.statementModalIsOpen = false;
  state.delivery = {
    type: '',
    name: '',
    price: 0,
    text: '',
  };
  state.cart = [];
  state.wizardOptions = {
    accessoriesRefetch: true,
    declaredPower: 0,
    modules: [],
    savedAccessories: [],
    incompatibleSteps: [],
    incompatibleSubSteps: [],
    inverters: [],
    optimizers: [],
    roofConstruction: {
      roofType: ROOF_TYPE.PitchedRoof,
      pourType: POUR_TYPE.BLACHODACHOWKA,
      installationMethod: INSTALLATION_METHODS.Invasive,
      rafterSpacing: '',
      panelOrientation: '',
      screwType: '',
      handleType: '',
      tcsType: '',
      verticalRowNumber: 0,
      horizontalRowNumber: 0,
      verticalModulesNumbers: [],
      horizontalModulesNumbers: [],
      clampType: CLAMP_TYPES.SREBRNE,
      inclinationAngle: '',
    },
    constructionCalculatorAttributes: { length: 0, height: 0, quantity: 0, thickness: 0 },
    wizardType: state.wizardOptions.wizardType,
    confirmResetModal: state.wizardOptions.confirmResetModal,
  };
};

const state = getDefaultState();

const actions = {
  async checkForDiscounts({ commit }, { cart }) {
    const response = await BasketService.getBasketDiscouts(cart);
    commit('calculateCartByDiscounts', response);
  },
  async login({ commit }, { email, password }) {
    return await AuthService.login(email, password).then((res) => {
      commit('login', res);
    });
  },

  async initLocale({ commit }) {
    const res = await AuthService.checkUser();
    const lang = res.user.lang;
    i18n.locale = lang;
    commit('setUserData', res);
  },

  async changeLocale({ commit }, { id, updatedUserData }) {
    if (id) {
      await UserService.partialUpdate(id, updatedUserData);
    }
    i18n.locale = updatedUserData.lang;
    commit('updateLocale', updatedUserData.lang);
  },

  async resetLocale({ commit }) {
    const browserLocale = navigator.language;
    const splitedLocale = browserLocale.includes('-') ? browserLocale.split('-')[0] : browserLocale;
    i18n.locale = splitedLocale;
    commit('updateLocale', splitedLocale);
  },

  async changeCurrency({ commit }, { id, updatedUserData }) {
    await UserService.partialUpdate(id, updatedUserData);
    commit('updateCurrency', updatedUserData.currency);
  },

  async resetDeliveryCountryCode({ commit }) {
    const browserLocale = navigator.language;
    i18n.locale = browserLocale;
    commit('updateLocale', browserLocale);
  },

  async checkUser({ commit }) {
    return await AuthService.checkUser().then((res) => {
      commit('setUserData', res);
    });
  },

  setOrganization({ commit }, { org, id }) {
    commit('setOrganization', { org, id });
  },

  logout({ commit }) {
    commit('logout');
  },

  openStatementModal({ commit }) {
    commit('openStatementModal');
  },

  hideStatementModal({ commit }) {
    commit('hideStatementModal');
  },

  setWizardType({ commit }, type) {
    commit('setWizardType', type);
  },

  setQuantity({ commit }, quantity) {
    commit('setQuantity', quantity);
  },

  setThickness({ commit }, thickness) {
    commit('setThickness', thickness);
  },

  setLength({ commit }, length) {
    commit('setLength', length);
  },

  setHeight({ commit }, height) {
    commit('setHeight', height);
  },

  setRouteHistory({ commit }, { path }) {
    commit('setRouteHistory', path);
  },

  openWizardTypeWithConfirmation({ commit }, type) {
    commit('openWizardTypeWithConfirmation', type);
  },

  closeWizardTypeWithConfirmation({ commit }) {
    commit('closeWizardTypeWithConfirmation');
  },

  resetWizard({ commit }) {
    commit('resetWizard');
  },

  setRoofType({ commit }, type) {
    commit('setRoofType', type);
  },

  resetPourType({ commit }) {
    commit('resetPourType');
  },
};

const mutations = {
  setRouteHistory(state, path) {
    state.routeHistorySubCategory = path;
  },
  resetDeliveryCountryCode(state) {
    state.deliveryCountryCode = defaultCountryCode.PL;
  },
  updateLocale(state, newLocale) {
    state.locale = newLocale;
  },
  updateCurrency(state, newCurrency) {
    state.userData.currency = newCurrency;
  },
  setOrganization(state, { org, id }) {
    state.organization = {
      name: org,
      clientId: id,
    };
  },
  setDelivery(state, delivery) {
    state.delivery = delivery;
  },
  setEnteringView(state, { name, params }) {
    state.enteringView = {
      name,
      params,
    };
  },
  finishCreatingOfferForExternalClient(state) {
    state.organization = {
      name: '',
      clientId: '',
    };
  },
  resetOrder(state) {
    state.orderData = {
      products: [],
      deliveryData: '',
      deliveryDate: '',
      companyId: '',
      specialTotalPrice: 0,
    };
    state.mode = '';
    state.missingSkus = [];
    state.missingProducts = [];
    state.substitutes = [];
    state.orderInProgress = false;
    resetWizardState(state);
  },
  addSavedAccessories(state, accessories) {
    state.wizardOptions.savedAccessories = accessories;
    state.accessoriesRefetch = false;
  },
  resetCart(state) {
    state.cart = [];
  },
  setMode(state, mode) {
    state.mode = mode;
  },
  setSpecialTotalPrice(state, amount) {
    state.orderData.specialTotalPrice = amount;
  },

  resetAccessories(state) {
    state.accessoriesRefetch = true;
    state.wizardOptions.savedAccessories = [];
  },

  addToIncompatibleAccessories(state, accessory, step = 5) {
    state.incompatibleAccessories.push(accessory);
    addToIncompatibleStepsState(state, step);
  },
  removeFromIncompatibleAccessories(state, accessory, step = 5) {
    this.state.incompatibleAccessories = state.incompatibleAccessories.filter(
      (acces) => acces._id !== accessory._id
    );

    if (state.incompatibleAccessories.length === 0) {
      removeFromIncompatibleStepsState(state, step);
    }
  },
  addToIncompatibleSteps(state, step = state.wizardStep) {
    addToIncompatibleStepsState(state, step);
  },
  removeFromIncompatibleSteps(state, step) {
    removeFromIncompatibleStepsState(state, step);
  },
  removeFromIncompatibleSubSteps(state, step) {
    const exists = state.wizardOptions.incompatibleSubSteps.find((st) => st === step);
    if (exists) {
      state.wizardOptions.incompatibleSubSteps = state.wizardOptions.incompatibleSubSteps.filter(
        (st) => st !== step
      );
    }
  },
  addToIncompatibleSubSteps(state, step) {
    const exists = state.wizardOptions.incompatibleSubSteps.find((st) => st === step);
    if (!exists) {
      state.wizardOptions.incompatibleSubSteps.push(step);
    } else {
      state.wizardOptions.incompatibleSubSteps = state.wizardOptions.incompatibleSubSteps.filter(
        (st) => st !== step
      );
    }
  },
  addProductsToCart(state, products) {
    state.cart = products;
  },
  addAccessoryToCart(state, { product, showMessage = false }) {
    const exists = state.cart.find((prod) => prod._id === product._id);
    if (!exists) {
      state.cart.push({ ...product, specialTotalPrice: 0 });
      if (showMessage) {
        this._vm.$toasted.global.success({
          message: `${product.name} ${i18n.t('components.store.addToCartMessage')}`,
        });
      }
    }
  },
  calculateCartByDiscounts(state, response) {
    state.discounts = [...response.discounts];
    state.cart = [...response.items];
  },
  addToCart(state, { product, showMessage = true, onlyPriceChange = false }) {
    const exists = state.cart.find((prod) => prod._id === product._id);
    if (exists) {
      if (isQuantityOfAddedProductLowerThanStock(product, exists)) {
        if (!onlyPriceChange) {
          exists.quantity += parseInt(product.quantity);
        }
        if (showMessage) {
          this._vm.$toasted.global.success({
            message: `${product.name} ${i18n.t('components.store.addToCartMessage')}`,
          });
        }
      } else {
        this._vm.$toasted.global.error({
          message: i18n.t('components.store.storageWarningMessage'),
        });
      }
      if (product.specialTotalPrice !== undefined) {
        exists.specialTotalPrice = product.specialTotalPrice;
      }
    } else if (product.quantity > 0 && product.quantity <= product.stock) {
      state.cart.push({ ...product, specialTotalPrice: 0 });
      if (showMessage) {
        this._vm.$toasted.global.success({
          message: `${product.name} ${i18n.t('components.store.addToCartMessage')}`,
        });
      }
    }
  },

  updateQuantityInCart(state, product) {
    const exists = state.cart.find((prod) => prod._id === product._id);
    if (exists) {
      exists.quantity = product.quantity;
    }
  },
  removeFromCart(state, productId) {
    state.cart = state.cart.filter((prod) => prod._id !== productId);
    if (isEmpty(state.cart)) {
      state.orderData.specialTotalPrice = 0;
    }
  },
  handleWizardOptionsProducts(state, productId) {
    const module = state.wizardOptions.modules.find((pr) => pr._id === productId);
    const inverter = state.wizardOptions.inverters.find((pr) => pr._id === productId);
    const optimizer = state.wizardOptions.optimizers.find((pr) => pr._id === productId);
    const accessory = state.wizardOptions.savedAccessories.find((pr) => pr._id === productId);
    if (module) {
      const indexOfModule = state.wizardOptions.modules.indexOf(module);
      state.wizardOptions.modules.splice(indexOfModule, 1);
    }
    if (inverter) {
      const indexOfInverter = state.wizardOptions.inverters.indexOf(inverter);
      state.wizardOptions.inverters.splice(indexOfInverter, 1);
    }
    if (optimizer) {
      const indexOfOptimizer = state.wizardOptions.optimizers.indexOf(optimizer);
      state.wizardOptions.optimizers.splice(indexOfOptimizer, 1);
    }
    if (accessory) {
      const indexOfAccessory = state.wizardOptions.savedAccessories.indexOf(accessory);
      state.wizardOptions.savedAccessories.splice(indexOfAccessory, 1);
    }
  },
  setMissingSkus(state, skus) {
    state.missingSkus = [...skus];
  },
  setMissingProducts(state, products) {
    state.missingProducts = [...products];
  },
  setSubstitutes(state, substitutes) {
    if (substitutes?.lenght > 0) {
      state.substitutes = [...substitutes];
    }
  },
  setWizardSubStep(state, value) {
    state.wizardSubStep = value;
  },
  addModule(state, newModule) {
    setAccessoriesRefetch(state, true);

    state.wizardOptions.modules = [...state.wizardOptions.modules, newModule];
  },
  removeModule(state, moduleId) {
    setAccessoriesRefetch(state, true);

    state.wizardOptions.modules = state.wizardOptions.modules.filter((mod) => mod._id !== moduleId);
  },
  addInverter(state, newInverter) {
    setAccessoriesRefetch(state, true);

    state.wizardOptions.inverters = [...state.wizardOptions.inverters, newInverter];
  },
  removeInverter(state, inverterId) {
    setAccessoriesRefetch(state, true);

    state.wizardOptions.inverters = state.wizardOptions.inverters.filter(
      (inv) => inv._id !== inverterId
    );
  },
  addOptimizer(state, newOptimizer) {
    setAccessoriesRefetch(state, true);

    state.wizardOptions.optimizers = [...state.wizardOptions.optimizers, newOptimizer];
  },
  removeOptimizer(state, optimizerId) {
    setAccessoriesRefetch(state, true);

    state.wizardOptions.optimizers = state.wizardOptions.optimizers.filter(
      (opt) => opt._id !== optimizerId
    );
  },
  incrementSubStep(state) {
    const { pourType, installationMethod, roofType } = state.wizardOptions.roofConstruction;

    if (state.wizardSubStep === wizardSubStepsNames.POUR_TYPE) {
      handleIncrementIfUserOnPourTypeSelection(state, pourType, installationMethod, roofType);
    }

    if (state.wizardSubStep === wizardSubStepsNames.SCREW_TYPE) {
      handleIncrementIfUserOnScrewTypeSelection(state, pourType);
    }

    if (state.wizardSubStep === wizardSubStepsNames.HANDLE_TYPE) {
      handleIncrementIfUserOnHandleTypeSelection(state, pourType);
    }

    state.wizardSubStep++;
  },
  decrementSubStep(state) {
    const { pourType, handleType, installationMethod, roofType } =
      state.wizardOptions.roofConstruction;

    state[wizardResetMapped[state.wizardStep][state.wizardSubStep]] = '';

    if (
      pourType !== POUR_TYPE.BLACHODACHOWKA &&
      state.wizardSubStep === wizardSubStepsNames.HANDLE_TYPE
    ) {
      state.wizardSubStep--;
    }

    if (
      !tcsHandleTypes.includes(handleType) &&
      state.wizardSubStep === wizardSubStepsNames.RAFTER_SPACING &&
      pourType === POUR_TYPE.BLACHODACHOWKA
    ) {
      state.wizardSubStep = state.wizardSubStep - 2;
    }
    if (
      !tcsHandleTypes.includes(handleType) &&
      state.wizardSubStep === wizardSubStepsNames.RAFTER_SPACING &&
      pourType === POUR_TYPE.BLACHODACHOWKA
    ) {
      state.wizardSubStep = state.wizardSubStep - 2;
    }
    if (
      !tcsHandleTypes.includes(handleType) &&
      state.wizardSubStep === wizardSubStepsNames.RAFTER_SPACING &&
      !permamentPourTypes.includes(pourType)
    ) {
      state.wizardSubStep--;
    }

    if (
      roofType === ROOF_TYPE.FlatRoof &&
      installationMethod === INSTALLATION_METHODS.NonIvasive &&
      state.wizardSubStep === wizardSubStepsNames.HANDLE_TYPE
    ) {
      state.wizardSubStep--;
    }

    state.wizardSubStep--;
    if (state.wizardSubStep < 0) {
      state.wizardSubStep = 0;
    }
  },
  setWizardStep(state, value) {
    state.wizardStep = value;
  },
  incrementStep(state) {
    state.wizardStep++;
  },
  decrementStep(state) {
    if (wizardResetMapped[state.wizardStep] instanceof Array) {
      state[wizardResetMapped[state.wizardStep][0]] = '';
    } else {
      state[wizardResetMapped[state.wizardStep][0]] = '';
    }
    state.wizardStep--;

    if (state.wizardStep < 0) {
      state.wizardStep = 0;
    }
  },
  setUserData(state, userData) {
    state.userData = { ...userData.user };
    localStorage.setItem('userData', JSON.stringify({ ...userData.user }));
  },

  login(state, userData) {
    state.userData = { ...userData.user };
    i18n.locale = userData.user.lang;
    state.locale = userData.user.lang;
    localStorage.setItem('userData', JSON.stringify({ ...userData.user }));
    const location = state.enteringView.name ? routeForEnteringView(state) : { name: 'Options' };
    router.push(location);
  },

  logout(state) {
    state.userData = null;
    localStorage.removeItem('userData');
    router.push({ name: 'Login' });
    Object.assign(state, getDefaultState());
  },

  openStatementModal(state) {
    state.statementModalIsOpen = true;
  },

  hideStatementModal(state) {
    state.statementModalIsOpen = false;
  },

  setDeclaredPower(state, power) {
    if (isDifferent(state.wizardOptions.declaredPower, power)) {
      state.declaredPowerChanged = true;
      state.wizardOptions.declaredPower = power;
      setAccessoriesRefetch(state, true);
    } else {
      state.declaredPowerChanged = false;
    }
  },

  setPourType(state, pourType) {
    if (isDifferent(state.wizardOptions.roofConstruction.pourType, pourType)) {
      state.wizardOptions.roofConstruction.pourType = pourType;
      if (permamentPourTypes.includes(pourType)) {
        state.wizardOptions.roofConstruction.handleType = permamentHandleType[pourType];
      }
      resetPourTypesOptions(state);
      setAccessoriesRefetch(state, true);
    }
  },

  setRafterSpacing(state, rafterSpacing) {
    if (isDifferent(state.wizardOptions.roofConstruction.rafterSpacing, rafterSpacing)) {
      setAccessoriesRefetch(state, true);
      state.wizardOptions.roofConstruction.rafterSpacing = rafterSpacing;
    }
  },

  setInclinationAngle(state, inclinationAngle) {
    if (isDifferent(state.wizardOptions.roofConstruction.inclinationAngle, inclinationAngle)) {
      setAccessoriesRefetch(state, true);
      state.wizardOptions.roofConstruction.inclinationAngle = inclinationAngle;
    }
  },

  setPanelOrientation(state, panelOrientation) {
    if (isDifferent(state.wizardOptions.roofConstruction.panelOrientation, panelOrientation)) {
      setAccessoriesRefetch(state, true);
      state.wizardOptions.roofConstruction.panelOrientation = panelOrientation;
      resetModulesNumberRowsState(state);
    }
  },

  setVerticalRowNumber(state, rowNumber) {
    if (isDifferent(state.wizardOptions.roofConstruction.verticalRowNumber, rowNumber)) {
      setAccessoriesRefetch(state, true);
      state.wizardOptions.roofConstruction.verticalRowNumber = rowNumber ? rowNumber : 0;
      resetModulesNumbersState(state);
    }
  },

  setHorizontalRowNumber(state, rowNumber) {
    if (isDifferent(state.wizardOptions.roofConstruction.horizontalRowNumber, rowNumber)) {
      setAccessoriesRefetch(state, true);
      state.wizardOptions.roofConstruction.horizontalRowNumber = rowNumber ? rowNumber : 0;
      resetModulesNumbersState(state);
    }
  },

  setVerticalModulesNumbers(state, modules) {
    if (isDifferent(state.wizardOptions.roofConstruction.verticalModulesNumbers, modules)) {
      setAccessoriesRefetch(state, true);
      state.wizardOptions.roofConstruction.verticalModulesNumbers = modules;
    }
  },

  setHorizontalModulesNumbers(state, modules) {
    if (isDifferent(state.wizardOptions.roofConstruction.horizontalModulesNumbers, modules)) {
      setAccessoriesRefetch(state, true);
      state.wizardOptions.roofConstruction.horizontalModulesNumbers = modules;
    }
  },

  setClampType(state, type) {
    if (isDifferent(state.wizardOptions.roofConstruction.clampType, type)) {
      setAccessoriesRefetch(state, true);
      state.wizardOptions.roofConstruction.clampType = type;
    }
  },

  setScrewType(state, type) {
    if (isDifferent(state.wizardOptions.roofConstruction.screwType, type)) {
      setAccessoriesRefetch(state, true);
      state.wizardOptions.roofConstruction.screwType = type;
    }
  },

  setHandleType(state, type) {
    if (isDifferent(state.wizardOptions.roofConstruction.handleType, type)) {
      setAccessoriesRefetch(state, true);
      state.wizardOptions.roofConstruction.handleType = type;
    }
  },

  setTcsType(state, type) {
    if (isDifferent(state.wizardOptions.roofConstruction.tcsType, type)) {
      setAccessoriesRefetch(state, true);
      state.wizardOptions.roofConstruction.tcsType = type;
    }
  },

  setProducts(state, products) {
    state.orderData.products = products;
  },

  setDeliveryData(state, deliveryData) {
    state.orderData.deliveryData = deliveryData;
  },

  setDeliveryDate(state, deliveryDate) {
    state.orderData.deliveryDate = deliveryDate;
  },

  setOrderCompanyId(state, companyId) {
    state.orderData.companyId = companyId;
  },

  resetWizard(state) {
    resetWizardState(state);
  },

  resetWizardBeforeStart(state) {
    state.missingProducts = [];
    state.substitutes = [];
    state.missingSkus = [];
    resetWizardState(state);
  },

  resetWizardOptions(state) {
    state.mode = '';
    state.wizardStep = 0;
    state.wizardSubStep = 0;
    state.declaredPowerChanged = false;
    state.accessoriesRefetch = true;
    state.wizardOptions = {
      declaredPower: 0,
      modules: [],
      savedAccessories: [],
      incompatibleSteps: [],
      incompatibleSubSteps: [],
      inverters: [],
      optimizers: [],
      roofConstruction: {
        roofType: ROOF_TYPE.PitchedRoof.value,
        pourType: POUR_TYPE.BLACHODACHOWKA,
        installationMethod: INSTALLATION_METHODS.Invasive,
        rafterSpacing: '',
        panelOrientation: '',
        screwType: '',
        handleType: '',
        tcsType: '',
        verticalRowNumber: 0,
        horizontalRowNumber: 0,
        verticalModulesNumbers: [],
        horizontalModulesNumbers: [],
        clampType: CLAMP_TYPES.SREBRNE,
        inclinationAngle: '',
      },
      constructionCalculatorAttributes: { length: 0, height: 0, quantity: 0, thickness: 0 },
      wizardType: state.wizardOptions.wizardType,
      confirmResetModal: state.wizardOptions.confirmResetModal,
    };
  },

  resetWizardAfterDeclaredPowerChanged(state) {
    state.cart = [];
    state.wizardOptions.incompatibleSteps = [];
    state.wizardOptions.incompatibleSubSteps = [];
    state.wizardOptions.modules = [];
    state.wizardOptions.inverters = [];
    state.wizardOptions.optimizers = [];
  },

  setDeliveryCountryCode(state, deliveryCountryCode) {
    state.deliveryCountryCode = deliveryCountryCode;
  },

  setWizardType(state, type) {
    state.wizardOptions.wizardType = type;
  },

  setQuantity(state, quantity) {
    state.wizardOptions.constructionCalculatorAttributes.quantity = quantity;
  },

  setLength(state, length) {
    state.wizardOptions.constructionCalculatorAttributes.length = length;
  },

  setHeight(state, height) {
    state.wizardOptions.constructionCalculatorAttributes.height = height;
  },

  setThickness(state, thickness) {
    state.wizardOptions.constructionCalculatorAttributes.thickness = thickness;
  },

  openWizardTypeWithConfirmation(state, type) {
    state.wizardOptions.confirmResetModal.open = true;
    state.wizardOptions.confirmResetModal.type = type;
  },

  closeWizardTypeWithConfirmation(state) {
    state.wizardOptions.confirmResetModal.open = false;
  },

  setRoofType(state, roofType) {
    state.wizardOptions.roofConstruction.roofType = roofType;
  },

  setInstallationMethod(state, installationMethod) {
    if (isDifferent(state.wizardOptions.roofConstruction.installationMethod, installationMethod)) {
      state.wizardOptions.roofConstruction.installationMethod = installationMethod;
      setAccessoriesRefetch(state, true);
    }
    state.wizardOptions.roofConstruction.installationMethod = installationMethod;
  },

  resetPourType(state) {
    state.wizardOptions.roofConstruction.pourType = POUR_TYPE.BLACHODACHOWKA;
  },
};

const getters = {
  routeHistorySubCategory(state) {
    return state.routeHistorySubCategory;
  },
  discounts(state) {
    return state.discounts;
  },
  orderDetails(state) {
    const { currency, currencies, companyId } = state.userData;
    return {
      items: state.orderData.products,
      missingSkus: state.missingSkus,
      missingProducts: state.missingProducts,
      companyId: state.orderData.companyId || companyId,
      specialTotalPrice: state.orderData.specialTotalPrice,
      delivery: {
        deliveryDate: state.orderData.deliveryDate,
        ...state.orderData.deliveryData,
        ...state.delivery,
        countryCode: state.deliveryCountryCode,
      },
      currency: {
        name: currency,
        value: currencyRate(currency, currencies),
      },
    };
  },
  deliveryCountryCode(state) {
    return state.deliveryCountryCode;
  },
  userData(state) {
    return state.userData;
  },
  declaredPower(state) {
    return state.wizardOptions.declaredPower;
  },
  pourType(state) {
    return state.wizardOptions.roofConstruction.pourType;
  },
  rafterSpacing(state) {
    return state.wizardOptions.roofConstruction.rafterSpacing;
  },
  panelOrientation(state) {
    return state.wizardOptions.roofConstruction.panelOrientation;
  },
  horizontalRowNumber(state) {
    return state.wizardOptions.roofConstruction.horizontalRowNumber;
  },
  verticalRowNumber(state) {
    return state.wizardOptions.roofConstruction.verticalRowNumber;
  },
  verticalModulesNumbers(state) {
    return state.wizardOptions.roofConstruction.verticalModulesNumbers;
  },
  horizontalModulesNumbers(state) {
    return state.wizardOptions.roofConstruction.horizontalModulesNumbers;
  },
  clampType(state) {
    return state.wizardOptions.roofConstruction.clampType;
  },
  screwType(state) {
    return state.wizardOptions.roofConstruction.screwType;
  },
  handleType(state) {
    return state.wizardOptions.roofConstruction.handleType;
  },
  tcsType(state) {
    return state.wizardOptions.roofConstruction.tcsType;
  },
  selectedModules(state) {
    return state.wizardOptions.modules;
  },
  selectedInverters(state) {
    return state.wizardOptions.inverters;
  },
  selectedOptimizers(state) {
    return state.wizardOptions.optimizers;
  },
  cart(state) {
    return state.cart;
  },
  accessoriesRefetch(state) {
    return state.accessoriesRefetch;
  },
  selectedLanguage(state) {
    return state.locale;
  },
  selectedCurrency(state) {
    return state.userData.currency;
  },
  selectedCurrencyObj(state) {
    const { currency: name, currencies } = state.userData;
    return find(currencies, { name });
  },
  currencies(state) {
    return state.userData.currencies;
  },
  cartTotalAmount(state) {
    const { cart } = state;
    const discountGroup = state.userData.discountGroup ?? discountGroups.BASIC;
    const productsPrices = cart.map((product) => {
      const { discountPrices, discountPricesQuanities } = product;
      const productPrice = getPriceByQuantityAndGroup(
        product.quantity,
        discountGroup,
        discountPrices,
        discountPricesQuanities
      );
      return product.specialTotalPrice
        ? product.specialTotalPrice
        : product.quantity * productPrice;
    });
    return round(sum(productsPrices), 2);
  },
  cartDefaultAmount(state) {
    const { cart } = state;
    const discountGroup = state.userData.discountGroup ?? discountGroups.BASIC;
    const productsPrices = cart.map((product) => {
      const { discountPrices, discountPricesQuanities } = product;
      const productPrice = getPriceByQuantityAndGroup(
        product.quantity,
        discountGroup,
        discountPrices,
        discountPricesQuanities
      );
      return product.quantity * productPrice;
    });
    return round(sum(productsPrices), 2);
  },
  specialTotalPrice(state) {
    const productsPrices = state.cart.map((product) => {
      const productPrice = getPriceByQuantityAndGroup(
        product.quantity,
        discountGroups.BASIC,
        product.discountPrices,
        product.discountPricesQuanities
      );
      return product.specialTotalPrice
        ? product.specialTotalPrice
        : product.quantity * productPrice;
    });
    return state.orderData.specialTotalPrice
      ? state.orderData.specialTotalPrice
      : round(sum(productsPrices), 2);
  },
  mode(state) {
    return state.mode;
  },
  incompatibleSteps(state) {
    return state.wizardOptions.incompatibleSteps;
  },
  incompatibleSubSteps(state) {
    return state.wizardOptions.incompatibleSubSteps;
  },
  wizardStep(state) {
    return state.wizardStep;
  },
  wizardSubStep(state) {
    return state.wizardSubStep;
  },
  wizardOptions(state) {
    return state.wizardOptions;
  },
  missingSkus(state) {
    return state.missingSkus;
  },
  missingProducts(state) {
    return state.missingProducts;
  },
  declaredPowerChanged(state) {
    return state.declaredPowerChanged;
  },
  savedAccessories(state) {
    return state.wizardOptions.savedAccessories;
  },
  delivery(state) {
    return state.delivery;
  },
  organization(state) {
    return state.organization;
  },
  enteringView(state) {
    return state.enteringView;
  },
  wizardType(state) {
    return state.wizardOptions.wizardType;
  },
  constructionCalculatorAttributes(state) {
    return state.wizardOptions.constructionCalculatorAttributes;
  },
  confirmResetModal(state) {
    return state.wizardOptions.confirmResetModal;
  },
  roofType(state) {
    return state.wizardOptions.roofConstruction.roofType;
  },
  installationMethod(state) {
    return state.wizardOptions.roofConstruction.installationMethod;
  },
  inclinationAngle(state) {
    return state.wizardOptions.roofConstruction.inclinationAngle;
  },
  statementModalIsOpen(state) {
    return state.statementModalIsOpen;
  },
  substitutes(state) {
    return state.substitutes;
  },
};

const plugins = [createPersistedState()];

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  plugins,
});
