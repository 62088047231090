import config from '../../configuration.json';
import axios from 'axios';

const url = `${config.VUE_APP_API_URL}/help`;

axios.defaults.withCredentials = true;

class HelpService {
  static async sendHelpRequest(firstName, email, phone) {
    const res = await axios.post(`${url}`, { firstName, email, phone });
    return res.data;
  }
}

export default HelpService;
