import { supportedCurrencies } from '../static/supportedCurrencies';
import { find } from 'lodash';

export const isPLN = (currency) => {
  return currency === supportedCurrencies.PLN.value;
};

export const currencyRate = (currency, currencies) => {
  const matchedCurrency = find(currencies, { name: currency });
  return matchedCurrency?.value;
};
