<template>
  <svg
    width="148"
    height="124"
    viewBox="0 0 148 124"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.558 0.696498C24.461 1.529 23.0255 2.822 22.049 4.8035L21.158 6.5555V12.128V17.672H24.0305H26.903V12.9335C26.903 7.3895 27.104 6.6725 28.856 6.212C29.459 6.0395 33.05 5.8955 37.2425 5.8955C41.4365 5.8955 45.026 6.0395 45.629 6.212C47.381 6.671 47.582 7.3895 47.582 12.9335V17.672H50.4545H53.327V12.128V6.5555L52.436 4.8035C51.4595 2.7935 49.994 1.5005 47.8685 0.696498C46.547 0.208998 45.5705 0.150496 37.1555 0.150496C28.856 0.178996 27.764 0.237498 26.558 0.696498Z"
      fill="currentColor"
    />
    <path
      d="M100.086 0.696498C97.9895 1.529 96.554 2.822 95.5775 4.8035L94.688 6.5555V12.128V17.672H97.5605H100.433V12.9335C100.433 7.3895 100.634 6.6725 102.386 6.212C102.989 6.0395 106.58 5.8955 110.772 5.8955C114.966 5.8955 118.556 6.0395 119.159 6.212C120.911 6.671 121.112 7.3895 121.112 12.9335V17.672H123.984H126.857V12.128V6.5555L125.966 4.8035C124.989 2.7935 123.524 1.5005 121.398 0.696498C120.077 0.208998 119.1 0.150496 110.685 0.150496C102.384 0.178996 101.292 0.237498 100.086 0.696498Z"
      fill="currentColor"
    />
    <path
      d="M12.5419 20.744C10.9339 20.9735 9.15345 21.548 7.83195 22.208C5.99445 23.1275 3.26595 25.712 2.23095 27.551C0.363449 30.8255 0.450449 28.097 0.535949 72.845L0.621449 113.458L1.68495 115.7C3.49395 119.492 6.79695 122.219 10.9624 123.398C13.1164 124.001 134.898 124.001 137.052 123.398C141.216 122.221 144.519 119.492 146.33 115.7L147.392 113.459L147.477 72.8465C147.534 36.3125 147.506 32.033 147.075 30.626C145.839 26.2595 142.451 22.814 137.798 21.176C136.592 20.7455 131.279 20.6885 74.8685 20.6585C40.9759 20.63 12.9439 20.6585 12.5419 20.744ZM136.391 27.3215C138.143 27.896 139.779 29.3315 140.642 31.055L141.36 32.462L141.446 71.7245C141.503 110.729 141.503 110.987 140.928 112.568C140.153 114.578 138.486 116.273 136.448 117.049C134.868 117.623 134.609 117.623 74.006 117.623C13.6039 117.623 13.1149 117.623 11.5924 117.049C9.72495 116.33 8.08845 114.838 7.25595 113.056L6.65295 111.734V72.098V32.462L7.37145 31.055C7.77345 30.2795 8.57745 29.2175 9.20895 28.7C11.4499 26.7755 6.48045 26.9195 73.8905 26.891C128.522 26.8625 135.213 26.9195 136.391 27.3215Z"
      fill="currentColor"
    />
    <path
      d="M106.463 61.9025V67.6475H100.575H94.688V72.0995V76.5515H100.575H106.463V82.439V88.3265H110.771H115.079V82.439V76.5515H120.966H126.854V72.0995V67.6475H120.966H115.079V61.9025V56.1575H110.771H106.463V61.9025V61.9025Z"
      fill="currentColor"
    />
    <path
      d="M21.158 72.0995V76.5515H37.2425H53.327V72.0995V67.6475H37.2425H21.158V72.0995Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'BatteryIcon',
};
</script>
