<template>
  <v-menu offset-y :close-on-content-click="true">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon class="mr-6" v-bind="attrs" v-on="on">
        <v-icon :class="isActive ? 'icon__active' : 'icon'">{{ icon }}</v-icon>
      </v-btn>
    </template>
    <v-list>
      <router-link
        v-for="link in links"
        :key="link.wizard ? link.name + link.wizard.name : link.name"
        :to="{ name: link.name }"
      >
        <div @click="setWizardTypeAsAssistant(link)">
          <v-list-item class="hover-item-popup">
            <v-list-item-content> {{ link.title }} </v-list-item-content>
          </v-list-item>
        </div>
      </router-link>
    </v-list>
  </v-menu>
</template>
<script>
import { wizardTypes } from '@/static/wizardTypes';

export default {
  name: 'NavbarDropdownButton',
  props: ['links', 'icon'],
  computed: {
    isActive() {
      let isActive = false;
      const matchedComponents = this.$route.matched.map((route) => route.name);
      const dropdownComponents = this.links.map((link) => link.name);
      const sameElements = dropdownComponents.filter((component) =>
        matchedComponents.includes(component)
      );
      isActive = sameElements.length > 0;
      return isActive;
    },
  },
  methods: {
    async setWizardTypeAsAssistant(link) {
      const wizardType = wizardTypes[link?.wizard?.name];

      if (this.$route.path.includes('doradca') && wizardType) {
        await this.$store.dispatch('openWizardTypeWithConfirmation', wizardType);
      } else if (wizardType) {
        await this.$store.dispatch('setWizardType', wizardType);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.v-list {
  padding: 0;
}
@mixin fill($opacity) {
  fill-opacity: $opacity;
  fill: white;
}
.icon {
  @include fill(0.7);
  &__active {
    @include fill(1);
  }
}
</style>
