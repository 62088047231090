<template>
  <svg width="42" height="62" viewBox="0 0 42 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.7 1.8C0.2 3.4 0 6.7 0 27.8C0 48.3 0.2 52.1 1.6 53.4C3.9 55.8 38.1 55.8 40.4 53.4C41.8 52.1 42 48.3 42 27.6C42 5.3 41.8 3.2 40.2 1.7C38.6 0.199996 35.7 -3.8743e-06 20.8 -3.8743e-06C5.1 -3.8743e-06 3.1 0.199996 1.7 1.8ZM39.5 4.2C39.8 5.5 39.9 16.8 39.8 29.5L39.5 52.5H21H2.5L2.2 29.5C2.1 16.8 2.2 5.5 2.5 4.2C3 2 3.2 2 21 2C38.8 2 39 2 39.5 4.2Z"
      fill="currentColor"
    />
    <path
      d="M10 11.4C10 21.3 10.8 22 21 22C31.2 22 32 21.3 32 11.4C32 1.6 30.2 1.8 29.8 11.7L29.5 19.5H21H12.5L12.2 11.7C11.8 1.8 10 1.6 10 11.4Z"
      fill="currentColor"
    />
    <path
      d="M17.2 7.20001C15.1 9.30001 15.8 13.8 18.5 15.6C20.8 17.1 21.2 17.1 23.5 15.6C25.2 14.5 26 13 26 11.2C26 7.70001 24.4 6.00001 21 6.00001C19.6 6.00001 17.9 6.50001 17.2 7.20001ZM23.8 9.20001C25.4 10.8 25.3 11.5 23.4 13.4C21.5 15.4 20.5 15.4 18.6 13.4C17.4 12.2 17.3 11.4 18 9.90001C19.2 7.80001 22 7.40001 23.8 9.20001Z"
      fill="currentColor"
    />
    <path
      d="M11.7 34.8C10.4 36.2 10 38.6 10 43.8C10 47.9 10.4 51 11 51C11.6 51 12 47.9 12 43.6C12 37.8 12.3 36.1 13.6 35.6C16.4 34.5 27.6 35 28.8 36.2C29.5 36.9 30 40.2 30 44.2C30 48.1 30.4 51 31 51C31.6 51 32 47.9 32 43.7C32 34 31 33 20.8 33C14.8 33 13 33.4 11.7 34.8Z"
      fill="currentColor"
    />
    <path
      d="M20 41C20 42.6 20.5 44 21 44C21.6 44 22 42.6 22 41C22 39.3 21.6 38 21 38C20.5 38 20 39.3 20 41Z"
      fill="currentColor"
    />
    <path
      d="M15.6 43.6C14.6 46.3 17.6 50 20.8 50C24.4 50 26 48.3 26 44.8C26 41.2 24.2 41.1 23.8 44.7C23.6 46.8 22.9 47.6 21.1 47.8C18.4 48.2 16.8 46.3 17.6 43.7C18.3 41.5 16.4 41.4 15.6 43.6Z"
      fill="currentColor"
    />
    <path
      d="M6.6 58.2C5.4 60.9 6.8 62 11.1 62C15.3 62 17.3 59.9 15.1 57.7C14.3 56.9 14 57 14 58.3C14 60.7 8.3 60.7 7.7 58.2C7.3 56.6 7.3 56.6 6.6 58.2Z"
      fill="currentColor"
    />
    <path
      d="M26.6 58.2C25.4 60.9 26.8 62 31.1 62C35.3 62 37.3 59.9 35.1 57.7C34.3 56.9 34 57 34 58.3C34 60.7 28.3 60.7 27.7 58.2C27.3 56.6 27.3 56.6 26.6 58.2Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'InverterIcon',
};
</script>
